import { useWindowWidth } from '@react-hook/window-size'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import { trackHandler, useSupportedBrowser } from 'uneeq-react-core'
import { Language, UnsupportedBrowser } from 'uneeq-react-ui'
import { useTranslation } from 'react-i18next'
import Logo from './Logo'
import Config from '../../config'
import styles from './styles'
import './styles.css'
import ReactFlagsSelect from 'react-flags-select'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as CloseIcon } from '../assets/img/close.svg'
import Portraits from './paths'

interface HomeProps {
  startSession: (token: string) => void
}
const Home: React.FC<HomeProps> = ({ startSession }) => {
  const config = Config.getInstance()
  const { isBrowserSupported } = useSupportedBrowser()
  const [showPasscode, setShowPasscode] = useState(false)
  const [changingLanguage, setChangingLanguage] = useState(false)
  const { t, i18n } = useTranslation()
  const [readCheck, setReadCheck] = useState(false)
  const [consentCheck, setConsentCheck] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [permissionsDenied, setPermissionsDenied] = useState(false)
  const [modalType, setmodalType] = useState('')
  const [left, setLeft] = useState(0)
  useEffect(() => {
    localStorage.setItem('scrollPosition', '0')
  })
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width:768px)'
  })

  const [CORS, setCORS] = useState(0)
  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    async function wakingUpFunction() {
      //Wake the function up
      try {
        const response = await fetch(config.server + '?action=query', {
          method: 'POST',
          body: JSON.stringify({ warmingUp: true }),
          headers: { 'Content-Type': 'application/json' }
        })
        setCORS(response.status)
        console.info(
          `wakingUpFunction - RESPONSE: ${response.status} | CORS: ${CORS} `
        )
      } catch (e) {
        setCORS(500)
        console.info(`wakingUpFunction | CORS: ${CORS} `)
      }
    }
    CORS == 0 ? wakingUpFunction() : console.info(`CORS: ${CORS} `)
  }, [CORS])

  if (!config.languages) throw 'At least one language must be defined'

  // Selected the first language in the config
  const [selectedLanguage, setSelectedLanguage] = useState(config.languages[0])

  function displayAvatarName() {
    if (config?.name === 'Diana' && selectedLanguage === 'FI') {
      return 'Elina'
    } else {
      return config.name
    }
  }

  const languageInfos = config.languages.map(l => {
    const { label, flag } = t(`languages.${l}`, { returnObjects: true })
    return new Language(l, flag, label)
  })

  const getLabels = () => {
    var obj = {}
    for (const languageInfo of languageInfos) {
      let code = languageInfo.flagCode
      // @ts-ignore
      obj[code] = languageInfo.label
    }
    return obj
  }

  useEffect(() => {
    // @ts-ignore
    const { langCode } = languageInfos.find(
      l => l.langCode == selectedLanguage || l.flagCode == selectedLanguage
    )
    console.info(langCode)
    setChangingLanguage(true)
    i18n.changeLanguage(langCode.toLowerCase())
    setChangingLanguage(false)
  }, [selectedLanguage])
  if (showPasscode && !isBrowserSupported) {
    return <UnsupportedBrowser close={() => setShowPasscode(false)} />
  }

  if (showPasscode && !isBrowserSupported) {
    return <UnsupportedBrowser close={() => setShowPasscode(false)} />
  }

  function openModal(modalType) {
    setEnabled(true)
    setmodalType(modalType)
  }
  function closeModal() {
    setEnabled(false)
    setmodalType('')
  }
  interface privacyParagraph {
    title: string
    contents: Array<string>
  }

  // TO BE OPTIMIZED LATER AND EXPANDED TO buttonStyling UNDERLINE, BOLD AND ITALIC
  function styleContent(someString) {
    const httpRegex = new RegExp('(https?://.*?.[a-z]{2,4}/[^ .,]*)')
    const emailRegex = new RegExp('([\\w.-]+@[\\w.-]+\\.[a-z]{2,4})', 'g')
    let hyperlinkTextSplit = someString.split(
      new RegExp('\\[[h|m]\\](.*?)\\[\\/[h|m]\\]')
    )
    let result: JSX.Element[] = []
    let i = 0
    if (hyperlinkTextSplit.length == 1) {
      let httpcheck = someString.split(httpRegex)
      let emailCheck = someString.split(emailRegex)
      if (httpcheck.length == 1 && emailCheck.length == 1) {
        result.push(<span key={i}>{hyperlinkTextSplit}</span>)
      } else {
        let splitRegex = new RegExp(
          [httpRegex, emailRegex].map(r => r.source).join('|'),
          'g'
        )
        let splitString = someString.split(splitRegex)
        result = splitString.map((word, index) => {
          if (word && word.match(httpRegex))
            return (
              <span key={index}>
                <a
                  href={word}
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {word}
                </a>{' '}
              </span>
            )
          else if (word && word.match(emailRegex))
            return (
              <span key={index}>
                <a
                  href={`mailto:${word}`}
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {word}
                </a>{' '}
              </span>
            )
          else return <span key={index}> {word}</span>
        })
      }
    } else {
      for (i = 1; i < hyperlinkTextSplit.length; i += 2) {
        let leadingText = hyperlinkTextSplit[i - 1]
        let text = hyperlinkTextSplit[i]
        let linkSplit = hyperlinkTextSplit[i + 1].split(
          new RegExp('\\((.*)\\)')
        )
        let link = linkSplit[1]
        let symbol = linkSplit[2] ? linkSplit[2] : ' '
        result.push(<span key={i - 1}>{leadingText}</span>)
        if (link.match(httpRegex)) {
          result.push(
            <span key={i}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
              {symbol}
            </span>
          )
        } else {
          result.push(
            <span>
              <span
                key={i}
                onClick={() => openModal(link)}
                style={{ color: '#1890ff', cursor: 'pointer' }}
              >
                {text}
              </span>
              {symbol}
            </span>
          )
        }
      }
    }
    return result
  }
  function Modal({ enabled, modalType }): JSX.Element {
    const ref = useRef<HTMLDivElement>(null)
    const getModalContent = modalType => {
      if (modalType.toLowerCase() !== 'consent') {
        let titles = i18n.t(
          'PrivacyPolicy.' + modalType + 'Content.' + modalType + 'Subtitles',
          { returnObjects: true }
        )
        let subs = i18n.t(
          'PrivacyPolicy.' +
            modalType +
            'Content.' +
            modalType +
            'SubtitleContent',
          { returnObjects: true }
        )
        if (Array.isArray(titles) && Array.isArray(subs)) {
          let result: privacyParagraph[] = titles.map((title, index) => {
            return { title: titles[index], contents: subs[index] }
          })
          return result
        }
      } else {
        let contents = i18n.t(
          'PrivacyPolicy.' + modalType + 'Content.' + modalType + 'Content',
          { returnObjects: true }
        )
        if (Array.isArray(contents[0])) {
          let result: string[] = contents[0].map((content, index) => {
            return styleContent(content)
          })
          return result
        }
      }
    }
    const subtitles = useMemo(() => getModalContent(modalType), [modalType])

    if (enabled) {
      return (
        <Box sx={{ ...styles.modalOverlay }}>
          <Box
            sx={{
              ...styles.modalContainer,
              overflow: 'hidden',
              zIndex: 99,
              left: mediaQueryMobile ? '0' : left,
              mx: mediaQueryMobile ? 'auto' : '',
              mt: mediaQueryMobile ? '5vh' : '10vh',
              mb: mediaQueryMobile ? '5vh' : '',
              height: mediaQueryMobile ? '90vh' : '75vh',
              pr: '3vw',
              pl: mediaQueryMobile ? '3vw' : '',
              width: mediaQueryMobile ? '100%' : '33%'
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                background: 'white',
                borderRadius: '1.25rem',
                overflow: 'hidden'
              }}
            >
              <Flex sx={{ ...styles.modalHeader }}>
                <Flex sx={{ ...styles.closeContainer }} onClick={closeModal}>
                  <CloseIcon />
                </Flex>
                <Flex sx={{ ...styles.modalFadeTop }} />
              </Flex>
              <Box
                sx={{
                  ...styles.modalContent
                }}
              >
                <Text
                  sx={{
                    ...styles.modaltitleText
                  }}
                >
                  {' '}
                  {t(
                    'PrivacyPolicy.' +
                      modalType +
                      'Content.' +
                      modalType +
                      'Title'
                  )}
                </Text>
                {modalType.toLowerCase() !== 'consent' && (
                  <Text sx={{ ...styles.regularText }}>
                    {t('PrivacyPolicy.' + modalType + 'Content.disclaimer')}
                  </Text>
                )}
                {modalType.toLowerCase() !== 'consent' ? (
                  subtitles?.map((subtitle, index) => {
                    return (
                      <Text sx={{ whiteSpace: 'pre-line' }} key={index}>
                        <Text sx={{ ...styles.modalSectionTitle }}>
                          {subtitle.title}
                          {'\n'}
                        </Text>
                        {subtitle.contents?.map((element, secondaryIndex) => {
                          return (
                            <Text
                              sx={{ ...styles.regularText }}
                              key={secondaryIndex}
                            >
                              {styleContent(element)}
                            </Text>
                          )
                        })}
                      </Text>
                    )
                  })
                ) : (
                  <Text sx={{ whiteSpace: 'pre-line' }}>
                    {subtitles?.map((subtitle, index) => {
                      return (
                        <Text sx={{ ...styles.regularText }} key={index}>
                          {subtitle}
                        </Text>
                      )
                    })}
                  </Text>
                )}
              </Box>
              <Flex sx={{ ...styles.modalFooter }} />
            </Flex>
          </Box>
        </Box>
      )
    } else return <div></div>
  }

  const PrivacyPolicy = ({ setLeft }) => {
    const ref = useRef<HTMLDivElement>(null)
    const scrollRef = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
      function onResize() {
        setLeft(ref?.current?.offsetLeft)
      }
      onResize()
      window.addEventListener('resize', onResize)
      return () => window.removeEventListener('resize', onResize)
    }, [])

    useLayoutEffect(() => {
      if (scrollRef && scrollRef.current)
        scrollRef.current.scroll(
          0,
          Number(localStorage.getItem('scrollPosition'))
        )
    }, [])
    const handleScroll = () => {
      if (scrollRef && scrollRef.current) {
        const position = scrollRef.current?.scrollTop
        localStorage.setItem('scrollPosition', position.toString())
      }
    }
    useEffect(() => {
      if (scrollRef && scrollRef.current) {
        scrollRef.current.addEventListener('scroll', handleScroll, {
          passive: true
        })
        const node = scrollRef.current
        return () => {
          if (node) node.removeEventListener('scroll', handleScroll)
        }
      }
    }, [])
    return !permissionsDenied ? (
      <Box
        sx={{
          ...styles.textContainer2,
          margin: mediaQueryMobile ? 'auto' : '',
          marginTop:
            mediaQueryMobile && config.persona.style.home.portrait ? '0' : '',
          overflowY: 'scroll'
        }}
        ref={ref}
      >
        {!(config.persona.features.homeAlt === true) ? (
          <Box
            sx={{
              ...styles.scrollContainer,
              maxHeight: mediaQueryMobile ? '' : '60vh'
            }}
            ref={scrollRef}
          >
            <Text
              sx={{
                ...styles.regularText,
                ...styles.titleText
              }}
            >
              {t('Home.title')} {displayAvatarName() + ','}
            </Text>
            <Text
              sx={{
                ...styles.regularText,
                ...styles.subtitleText2
              }}
            >
              {t('Home.subtitleTop')}.
            </Text>
            <Text
              sx={{
                ...styles.regularText,
                ...styles.subtitleText2
              }}
            >
              {t('Home.subtitleBottom')}.
            </Text>

            <Text
              sx={{
                ...styles.regularText,
                ...styles.subtitleText2
              }}
            >
              {t('Home.Disclaimer.intro')}{' '}
              <u
                onClick={() => openModal('notice')}
                style={{ cursor: 'pointer', textDecorationSkipInk: 'none' }}
              >
                {t('Home.Disclaimer.privacyPolicy')}
              </u>{' '}
              {t('Home.Disclaimer.conjuction')}{' '}
              <u
                onClick={() => openModal('terms')}
                style={{ cursor: 'pointer', textDecorationSkipInk: 'none' }}
              >
                {t('Home.Disclaimer.terms')}
              </u>{' '}
              {t('Home.Disclaimer.condition')}{' '}
              <u
                onClick={() => openModal('consent')}
                style={{ cursor: 'pointer', textDecorationSkipInk: 'none' }}
              >
                {t('Home.Disclaimer.consent')}
              </u>{' '}
              {t('Home.Disclaimer.processing')}{' '}
            </Text>
            <Box
              sx={{ ...styles.mainCheckboxContainer }}
              onClick={() => {
                handleChange('read')
              }}
            >
              <Checkbox value={readCheck} />
              <Text
                sx={{
                  ...styles.regularText,
                  ...styles.checkboxText
                }}
              >
                {t('Home.consentRead')}{' '}
              </Text>
            </Box>
            <Box
              sx={{ ...styles.mainCheckboxContainer }}
              onClick={() => {
                handleChange('consent')
              }}
            >
              <Checkbox value={consentCheck} />
              <Text
                sx={{
                  ...styles.regularText,
                  ...styles.checkboxText
                }}
              >
                <p dangerouslySetInnerHTML={{ __html: t('Home.consent') }} />
              </Text>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              ...styles.scrollContainer,
              maxHeight: mediaQueryMobile ? '' : '60vh'
            }}
            ref={scrollRef}
          >
            <Text
              sx={{
                ...styles.regularText,
                ...styles.titleText
              }}
            >
              {t('HomeAlt.title')} {displayAvatarName() + ','}
            </Text>
            <Text
              sx={{
                ...styles.regularText,
                ...styles.subtitleText2
              }}
            >
              {t('HomeAlt.subtitleTop')}.
            </Text>
            <Text
              sx={{
                ...styles.regularText,
                ...styles.subtitleText2
              }}
            >
              {t('HomeAlt.subtitleBottom')}.
            </Text>

            <Text
              sx={{
                ...styles.regularText,
                ...styles.subtitleText2
              }}
            >
              {t('HomeAlt.Disclaimer.intro')}{' '}
              <u
                onClick={() =>
                  window.open(
                    'https://www.helgahilft.com/datenschutz',
                    '_blank'
                  )
                }
                style={{ cursor: 'pointer', textDecorationSkipInk: 'none' }}
              >
                {t('HomeAlt.Disclaimer.privacyPolicy')}
              </u>{' '}
              {t('HomeAlt.Disclaimer.conjuction')}{' '}
              <u
                onClick={() => openModal('terms')}
                style={{ cursor: 'pointer', textDecorationSkipInk: 'none' }}
              >
                {t('HomeAlt.Disclaimer.terms')}
              </u>{' '}
              {t('HomeAlt.Disclaimer.outro')}{' '}
            </Text>
            <Box
              sx={{ ...styles.mainCheckboxContainer }}
              onClick={() => {
                handleChange('read')
              }}
            >
              <Checkbox value={readCheck} />
              <Text
                sx={{
                  ...styles.regularText,
                  ...styles.checkboxText
                }}
              >
                {t('HomeAlt.consentRead')}{' '}
              </Text>
            </Box>
            <Box
              sx={{ ...styles.mainCheckboxContainer }}
              onClick={() => {
                handleChange('consent')
              }}
            >
              <Checkbox value={consentCheck} />
              <Text
                sx={{
                  ...styles.regularText,
                  ...styles.checkboxText
                }}
              >
                <p dangerouslySetInnerHTML={{ __html: t('HomeAlt.consent') }} />
              </Text>
            </Box>
          </Box>
        )}
        <Box sx={styles.buttonContainer}>
          <ActionButton
            type={buttonTypes.BUTTON_START}
            onclick={() => {
              if (readCheck && consentCheck)
                trackHandler(startSession, 'lets-chat-btn')(true)
            }}
            buttonStyling={{
              ...styles.letsChatButton2,
              background:
                readCheck && consentCheck
                  ? config.persona.style.colors.secondary?.background
                  : config.persona.style.colors.secondaryAlt?.background,
              opacity: readCheck && consentCheck ? '1' : '0.5',
              cursor: readCheck && consentCheck ? 'pointer' : 'auto !important'
            }}
            textStyling={{ ...styles.regularText, ...styles.buttonText }}
          />
          <ActionButton
            type={buttonTypes.BUTTON_DENY}
            onclick={() => {
              if (!(readCheck && consentCheck)) {
                setPermissionsDenied(true)
                setReadCheck(false)
                setConsentCheck(false)
              }
            }}
            buttonStyling={{
              ...styles.letsChatButton2,
              background: !(readCheck && consentCheck)
                ? config.persona.style.colors.secondary?.background
                : config.persona.style.colors.secondaryAlt?.background,
              opacity: !(readCheck && consentCheck) ? '1' : '0.5',
              cursor: !(readCheck && consentCheck)
                ? 'pointer'
                : 'auto !important'
            }}
            textStyling={{ ...styles.regularText, ...styles.buttonText }}
          />
        </Box>
      </Box>
    ) : config.persona.features.homeAlt === true ? (
      <Box
        sx={{
          ...styles.textContainer2,
          margin: mediaQueryMobile ? 'auto' : '',
          marginTop:
            mediaQueryMobile && config.persona.style.home.portrait ? '0' : ''
        }}
        ref={ref}
      >
        <Text
          sx={{
            ...styles.regularText,
            ...styles.subtitleText2,
            flexDirection: 'column'
          }}
        >
          <Text>
            {t('HomeAlt.PermissionsRejected.consentNotice')}
            <Text
              sx={{
                ...styles.regularText,
                ...styles.subtitleText2
              }}
            >
              {t('HomeAlt.PermissionsRejected.redirectIntro')}{' '}
              <u
                style={{ cursor: 'pointer', textDecorationSkipInk: 'none' }}
                onClick={() => {
                  window.open(config.persona.features.denyRedirect)
                }}
              >
                {config.persona.features.denyRedirect}
              </u>{' '}
              {t('HomeAlt.PermissionsRejected.redirectOutro')}
            </Text>
          </Text>
        </Text>
        <Box sx={styles.buttonContainer}>
          <ActionButton
            type={buttonTypes.BUTTON_RESTART}
            onclick={() => {
              setPermissionsDenied(false)
            }}
            buttonStyling={{
              ...styles.letsChatButton2,
              background: config.persona.style.colors.secondary?.background,
              cursor: 'pointer'
            }}
            textStyling={{ ...styles.regularText, ...styles.buttonText }}
          />
          <ActionButton
            type={buttonTypes.BUTTON_REDIRECT}
            onclick={() => {
              window.open(config.persona.features.denyRedirect, '_self')
            }}
            buttonStyling={{
              ...styles.letsChatButton2,
              background: config.persona.style.colors.secondary?.background,
              cursor: 'pointer'
            }}
            textStyling={{ ...styles.regularText, ...styles.buttonText }}
          />
        </Box>
      </Box>
    ) : (
      <Box
        sx={{
          ...styles.textContainer2,
          margin: mediaQueryMobile ? 'auto' : '',
          marginTop:
            mediaQueryMobile && config.persona.style.home.portrait ? '0' : ''
        }}
        ref={ref}
      >
        <Text
          sx={{
            ...styles.regularText,
            ...styles.subtitleText2,
            flexDirection: 'column'
          }}
        >
          <Text>
            {t('Home.PermissionsRejected.consentNotice')}
            <Text
              sx={{
                ...styles.regularText,
                ...styles.subtitleText2
              }}
            >
              {t('Home.PermissionsRejected.redirectIntro')}{' '}
              <u
                style={{ cursor: 'pointer', textDecorationSkipInk: 'none' }}
                onClick={() => {
                  window.open(config.persona.features.denyRedirect)
                }}
              >
                {config.persona.features.denyRedirect}
              </u>{' '}
              {t('Home.PermissionsRejected.redirectOutro')}
            </Text>
          </Text>
        </Text>
        <Box sx={styles.buttonContainer}>
          <ActionButton
            type={buttonTypes.BUTTON_RESTART}
            onclick={() => {
              setPermissionsDenied(false)
            }}
            buttonStyling={{
              ...styles.letsChatButton2,
              background: config.persona.style.colors.secondary?.background,
              cursor: 'pointer'
            }}
            textStyling={{ ...styles.regularText, ...styles.buttonText }}
          />
        </Box>
      </Box>
    )
  }
  enum buttonTypes {
    BUTTON_START = 'start',
    BUTTON_RESTART = 'back',
    BUTTON_REDIRECT = 'redirect',
    BUTTON_DENY = 'consentDeny'
  }

  const ActionButton = ({ buttonStyling, textStyling, type, onclick }: any) => (
    <Button
      variant="outline"
      onClick={onclick}
      className="button"
      id="button"
      sx={{
        ...buttonStyling
      }}
    >
      <Text
        sx={{
          ...textStyling,
          color: config.persona.style.colors.text
            ? config.persona.style.colors.text?.color
            : 'white'
        }}
      >
        {t('Home.' + type)}{' '}
      </Text>
    </Button>
  )
  const handleChange = type => {
    if (type == 'read') setReadCheck(readCheck => !readCheck)
    else setConsentCheck(consentCheck => !consentCheck)
  }
  const Checkbox = ({ value }) => {
    return (
      <Box sx={styles.checkboxContainer}>
        <input type="checkbox" style={styles.checkbox} checked={value} />
      </Box>
    )
  }

  if (!CORS) return <></>
  return (
    <>
      {config.persona.features.newPolicy ? (
        <Flex sx={{ ...styles.mainContainer }}>
          {config.persona.style.home.portrait && mediaQueryMobile && (
            <Flex
              sx={{
                ...styles.portraitContainer,
                minHeight: '4rem',
                justifyContent: 'flex-end'
              }}
            >
              <img
                src={Portraits[config.persona.style.home.portrait]}
                style={{
                  ...styles.portrait,
                  maxHeight: '6rem',
                  maxWidth: '6rem'
                }}
              />
            </Flex>
          )}
          <PrivacyPolicy setLeft={setLeft} />
          <Modal enabled={enabled} modalType={modalType} />
          {config.persona.features.logoVisible && (
            <Logo
              svg={config?.persona.style?.logo?.svg}
              url={config?.persona.style?.logo?.url}
              link={config?.persona.style?.logo?.link}
              fill={config?.persona.style?.logo?.fill}
              handleClick={() => {
                config?.persona.style?.logo?.link &&
                  window.open(config?.persona.style?.logo?.link, '_blank')
              }}
            />
          )}
          {!config.persona.features.disableLanguage && (
            <Box
              sx={{
                ...styles.languageBox
              }}
            >
              <ReactFlagsSelect
                id="language"
                className="language"
                countries={languageInfos.map(l => l.flagCode)}
                customLabels={getLabels()}
                placeholder={'selectLanguage'}
                showSelectedLabel={false}
                showOptionLabel={true}
                selectedSize={48}
                selected={
                  languageInfos.find(
                    x =>
                      x.flagCode == selectedLanguage ||
                      x.langCode == selectedLanguage
                  )?.flagCode || 'US'
                }
                onSelect={code => setSelectedLanguage(code)}
                selectButtonClassName="menu-flags-button"
              />
            </Box>
          )}
        </Flex>
      ) : (
        <>
          <Flex sx={styles.overlay}></Flex>
          <Flex sx={styles.mainContainer}>
            <Box sx={styles.textContainer}>
              <Box sx={styles.titleContainer}>
                <Text sx={styles.imSophieText}>
                  {t('Home.title')} {displayAvatarName()}
                </Text>
              </Box>
              {config.persona.name === 'AnaEsmo' ? (
                <Text sx={styles.subtitle}>
                  Your virtual assistant at the ESMO Asia Congress 2022.
                  {<br></br>}
                  {<br></br>}I am happy to provide you with basic information
                  about breast cancer.
                </Text>
              ) : (
                <Text sx={styles.subtitle}>
                  {t('Home.subtitleTop')}.{<br></br>}
                  {<br></br>}
                  {t('Home.subtitleBottom')}.
                </Text>
              )}
              <ActionButton
                type={buttonTypes.BUTTON_START}
                onclick={() => {
                  trackHandler(startSession, 'lets-chat-btn')(true)
                }}
                buttonStyling={{
                  ...styles.letsChatButton,
                  ...styles.startButton,
                  ...config.persona.style.colors.buttons.basic
                }}
                textStyling={{}}
              />
            </Box>
            {!config.persona.features.disableLanguage && (
              <Box sx={styles.flagBox}>
                <ReactFlagsSelect
                  id="language"
                  className="language"
                  countries={languageInfos.map(l => l.flagCode)}
                  customLabels={getLabels()}
                  placeholder={'selectLanguage'}
                  showSelectedLabel={false}
                  showOptionLabel={true}
                  selectedSize={48}
                  selected={
                    languageInfos.find(
                      x =>
                        x.flagCode == selectedLanguage ||
                        x.langCode == selectedLanguage
                    )?.flagCode || 'US'
                  }
                  onSelect={code => setSelectedLanguage(code)}
                  selectButtonClassName="menu-flags-button"
                />
              </Box>
            )}
          </Flex>
        </>
      )}
    </>
  )
}

export default Home
