const styles = {
  animatedFeedbackContainer: {
    label: 'animatedFeedbackContainer',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    zIndex: 1,
    width: '100%',
    minWidth: '2rem',
    cursor: 'auto'
  }
}
export default styles
